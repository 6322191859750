import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { navigate, Link as GatsbyLink } from 'gatsby';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import InnerHTML from 'dangerously-set-html-content';

import { rgba, breakpoints, getSlug, getPrefix } from '@utils';
import { SettingsContext } from '@stores/Settings';
import { buttonStyle, BUTTON_TYPES } from '@atoms/Button';
import Accordion from '@atoms/Accordion';
import Marquee from '@atoms/Marquee';
import Img from '@atoms/Img';
import SectionTitle from '@atoms/SectionTitle';
import TextModule from '@atoms/TextModule';
import Dots from '@atoms/Dots';
import WhatsInside from '@atoms/WhatsInside';
import NavigationButton, { NAVIGATION_TYPES } from '@atoms/NavigationButton';
import NutritionalTable from '@molecules/NutritionalTable';
import RetailersList from '@molecules/RetailersList';
import ImagePush from '@molecules/ImagePush';
import { RelatedCardsCarousel } from '@organisms/RelatedCards';
import { grid } from '@styles/grid';
import Close from '@icons/close.svg';

import HSR_0_5 from '@images/hsr/0-5_HSR_Icon.png';
import HSR_1_0 from '@images/hsr/1-0_HSR_Icon.png';
import HSR_1_5 from '@images/hsr/1-5_HSR_Icon.png';
import HSR_2_0 from '@images/hsr/2-0_HSR_Icon.png';
import HSR_2_5 from '@images/hsr/2-5_HSR_Icon.png';
import HSR_3_0 from '@images/hsr/3-0_HSR_Icon.png';
import HSR_3_5 from '@images/hsr/3-5_HSR_Icon.png';
import HSR_4_0 from '@images/hsr/4-0_HSR_Icon.png';
import HSR_4_5 from '@images/hsr/4-5_HSR_Icon.png';
import HSR_5_0 from '@images/hsr/5-0_HSR_Icon.png';

import {
  TOTAL_ANIMATION_DURATION,
  lifestyleTransition,
  marqueeTransition,
  productImageTransition,
  mainTransition,
} from './transitionStyles';

const TopContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-bottom: ${theme.spacing(13)};

    ${theme.mediaquery.sm(css`
      margin-bottom: ${theme.spacing(15)};
    `)}
  `}
`;

const LifestyleImgContainer = styled.div`
  ${({ theme, ratioSmall, ratioLarge }) => css`
    position: relative;
    overflow: hidden;
    height: 0;
    padding-top: calc(100% / ${ratioSmall});

    ${theme.mediaquery.sm(css`
      padding-top: calc(100% / ${ratioLarge});
    `)}
  `}
`;

const LifestyleImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${lifestyleTransition};
`;

const TopContainerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ theme }) => css`
    background-image: linear-gradient(
      to bottom,
      ${rgba(theme.colors.black, 0)},
      ${theme.colors.black}
    );
  `}
`;

const ProductImageContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 75vw;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      width: 30vw;
    `)}
  `}
`;

const ProductImg = styled(Img)`
  ${productImageTransition};
`;

const navigationButtonStyle = css`
  position: absolute;
  top: 25%;
`;

const PrevButton = styled(GatsbyLink)`
  ${navigationButtonStyle};
  left: ${({ theme }) => theme.spacing(-4)};
`;

const NextButton = styled(GatsbyLink)`
  ${navigationButtonStyle};
  right: ${({ theme }) => theme.spacing(-4)};
`;

const StyledMarquee = styled(Marquee)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  ${marqueeTransition};
`;

const StyledDots = styled(Dots)`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(-5)};
  left: 50%;
  transform: translateX(-50%);
`;

const Main = styled.div`
  ${({ productColor }) => css`
    --product-color: ${productColor};

    ${mainTransition};
  `}
`;

const HSRContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${theme.spacing(7)};
    img {
      width: 100%;
      height: auto;
      max-width: 150px;
    }

    a {
      ${theme.typography.bodyS};
      color: ${theme.colors.white};
      margin-top: ${theme.spacing(2)};
      text-decoration: underline;
    }
  `}
`;

const HSRLinkText = styled(TextModule)`
  ${({ theme }) => css`
    ${theme.typography.bodyS};
    color: ${theme.colors.white};
    margin-top: ${theme.spacing(2)};
  `}
`;

const Infos = styled.div`
  ${({ theme }) => css`
    padding: 0 var(--outer-gap);
    margin-top: ${theme.spacing(8)};
    text-align: center;

    ${theme.mediaquery.sm(css`
      width: calc(var(--col) * 8 - var(--inner-gap));
      margin: 0 auto;
      margin-top: ${theme.spacing(10)};
    `)}
  `}
`;

const infosTextStyle = css`
  ${({ theme }) => css`
    ${theme.typography.titleL};
    color: ${theme.colors.white};

    span {
      color: var(--product-color);
      text-transform: initial;
    }
  `}
`;

const Protein = styled.p`
  ${infosTextStyle};
`;

const Title = styled.h1`
  ${infosTextStyle};
  margin-top: ${({ theme }) => theme.spacing(4)};

  span {
    display: block;
    text-transform: uppercase;
  }
`;

const Intro = styled.p`
  ${({ theme }) => css`
    ${theme.typography.bodyL};
    color: ${theme.colors.white};
    margin-top: ${theme.spacing(4)};
  `}
`;

const StyledAccordion = styled(Accordion)`
  text-align: center;
  & > button {
    width: auto;
  }
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

const AccordionTitle = styled.p`
  ${({ theme, isSwaven }) => css`
    ${buttonStyle(theme, BUTTON_TYPES.primary)};
    display: block;
    ${isSwaven &&
    css`
      margin-left: auto;
      margin-right: auto;

      &.swn-wtb-btn-disabled,
      &.swn-awe-btn-disabled {
        display: none;
      }
    `}
  `}
`;

const MajorFacts = styled.ul`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(8)};
    padding: 0 var(--outer-gap);

    ${theme.mediaquery.sm(css`
      text-align: center;
    `)}
  `}
`;

const MajorFactsItem = styled.li`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(2)};

    &:first-of-type {
      margin-top: 0;
    }
  `}
`;

const MajorFact = styled.p`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    color: ${theme.colors.white};

    span {
      color: var(--product-color);
      text-transform: initial;
    }
  `}
`;

const Ingredients = styled(TextModule)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(5)};
    display: block;
    padding: 0 var(--outer-gap);

    ${theme.mediaquery.sm(css`
      text-align: center;
      width: calc(var(--col) * 8 - var(--inner-gap));
      margin-left: auto;
      margin-right: auto;
    `)}
  `}
`;

const StyledRetailersList = styled(RetailersList)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(3)};
  `}
`;

const WhatsInsideDescription = styled.p`
  ${({ theme }) => css`
    ${theme.typography.bodyL};
    color: ${theme.colors.white};
    margin-top: ${theme.spacing(5)};
    padding: 0 var(--outer-gap);

    ${theme.mediaquery.sm(css`
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      width: calc(var(--col) * 6 - var(--inner-gap));
    `)}
  `}
`;

const WhatsInsideList = styled.ul`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 150px));
    grid-gap: var(--inner-gap);
    justify-content: center;
    margin-top: ${theme.spacing(3)};
    padding: 0 var(--outer-gap);

    ${theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(6)};
      grid-template-columns: repeat(3, minmax(0, 150px));
    `)}
  `}
`;

const WhatsInsideLinkText = styled(TextModule)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(7)};
    text-align: center;
  `}
`;

const LegalNoticeWrapper = styled.div`
  ${({ theme }) => css`
    ${grid}
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: ${theme.colors.legalGray};
    color: ${theme.colors.white};
    z-index: 1;
  `}
`;

const LegalNotice = styled.div`
  ${({ theme }) => css`
    ${theme.typography.footnote}
    padding: ${theme.spacing(1)};
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${theme.mediaquery.md(css`
      grid-column: 2 / -2;
    `)}
  `}
`;

const CloseButton = styled.button`
  ${({ theme }) => css`
    width: 15px;
    margin-left: ${theme.spacing(2)};
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
    }
  `}
`;

const HSR_ICONS = {
  0.5: HSR_0_5,
  '1.0': HSR_1_0,
  1.5: HSR_1_5,
  '2.0': HSR_2_0,
  2.5: HSR_2_5,
  '3.0': HSR_3_0,
  3.5: HSR_3_5,
  '4.0': HSR_4_0,
  4.5: HSR_4_5,
  '5.0': HSR_5_0,
};

const Product = ({
  nutritionalTable,
  swavenData,
  retailers,
  imagePush,
  categoryPush,
  relatedCardsCarousel,
  product,
  majorFacts,
  ingredients,
  flavours,
  whatsInside,
  legalNotice,
  healthStarRating,
}) => {
  const [animate, setAnimate] = useState(true);
  const [legalVisible, setLegalVisible] = useState(true);
  const [currentId, setCurrentId] = useState(product.id);
  const [healthStarRatingImg, setHealthStarRatingImg] = useState(null);
  const {
    translations,
    clpSlug,
    localizedPageData,
    locale: currentLocale,
  } = useContext(SettingsContext);
  let timeout = null;

  const prefix = getPrefix(localizedPageData, currentLocale);

  const currentIndex = flavours.findIndex(
    (flavour) => flavour.id === product.id
  );

  const prevProduct = currentIndex !== 0 && flavours[currentIndex - 1];
  const nextProduct =
    currentIndex !== flavours.length - 1 && flavours[currentIndex + 1];
  const prevSlug =
    prevProduct.slug && getSlug(`${clpSlug}/${prevProduct.slug}`, prefix);
  const nextSlug =
    nextProduct.slug && getSlug(`${clpSlug}/${nextProduct.slug}`, prefix);

  const swavenScript = `
  <script>
    (function(e){try{var a=window.swnDataLayer=window.swnDataLayer||{};a.appId=e||a.appId,a.eventBuffer=a.eventBuffer||[],a.loadBuffer=a.loadBuffer||[],a.push=a.push||function(e){a.eventBuffer.push(e)},a.load=a.load||function(e){a.loadBuffer.push(e)};var t=document.getElementsByTagName("script")[0],n=document.createElement("script");n.async=!0,n.src="//wtb-tag.swaven.com/scripts/"+a.appId+"/tag.min.js",t.parentNode.insertBefore(n,t)}catch(e){console.log(e)}}("5fcf8f765694b7140576c42f"));
  </script>
  `;

  const navigateToProduct = (slug) => {
    setAnimate(false);
    timeout = setTimeout(() => {
      navigate(slug, {
        state: {
          from: 'product',
        },
      });
    }, TOTAL_ANIMATION_DURATION);
  };

  useEffect(() => {
    return () => {
      if (window.swnUnloadAll) {
        window.swnUnloadAll();
      }
      if (window.swnTagLoaded) {
        window.swnTagLoaded = false;
      }
      clearTimeout(timeout);
    };
  }, [timeout]);

  useEffect(() => {
    if (healthStarRating) {
      const formattedRating = healthStarRating.toFixed(1);
      setHealthStarRatingImg(HSR_ICONS[formattedRating]);
    }
  }, [healthStarRating]);

  return (
    <>
      {swavenData.ean && <InnerHTML html={swavenScript} />}
      <TopContainer>
        <LifestyleImgContainer
          ratioSmall={product.background.small.aspectRatio}
          ratioLarge={product.background.large.aspectRatio}
        >
          <CSSTransition
            appear={animate}
            in={animate}
            timeout={TOTAL_ANIMATION_DURATION}
            classNames="lifestyle"
          >
            <LifestyleImg
              small={product.background.small}
              medium={product.background.large}
            />
          </CSSTransition>
        </LifestyleImgContainer>
        <TopContainerOverlay />
        <CSSTransition
          appear={animate}
          in={animate}
          timeout={TOTAL_ANIMATION_DURATION}
          classNames="marquee"
        >
          <StyledMarquee
            lines={3}
            filled={product.flavour}
            outlined={`${product.category} ${product.protein}`}
          />
        </CSSTransition>
        <ProductImageContainer>
          <CSSTransition
            appear={animate}
            in={animate}
            timeout={TOTAL_ANIMATION_DURATION}
            classNames="product-image"
          >
            <ProductImg
              small={{
                ...product.image,
                sizes: `(min-width: ${breakpoints.sm}px) 30vw ,75vw`,
              }}
            />
          </CSSTransition>
          {currentIndex !== 0 && (
            <PrevButton
              to={prevSlug}
              onClick={(e) => {
                e.preventDefault();
                setCurrentId(prevProduct.id);
                navigateToProduct(prevSlug);
              }}
            >
              <NavigationButton as="div" direction={NAVIGATION_TYPES.left} />
            </PrevButton>
          )}
          {currentIndex !== flavours.length - 1 && (
            <NextButton
              to={nextSlug}
              onClick={(e) => {
                e.preventDefault();
                setCurrentId(nextProduct.id);
                navigateToProduct(nextSlug);
              }}
            >
              <NavigationButton as="div" direction={NAVIGATION_TYPES.right} />
            </NextButton>
          )}
        </ProductImageContainer>
        <StyledDots
          items={flavours.map(({ id, slug, color, flavour }) => ({
            label: flavour,
            id,
            color,
            slug: getSlug(`${clpSlug}/${slug}`, prefix),
            active: currentId === id,
          }))}
          onSelect={(item) => {
            setCurrentId(item.id);
            navigateToProduct(item.slug);
          }}
        />
      </TopContainer>
      <CSSTransition
        appear={animate}
        in={animate}
        timeout={TOTAL_ANIMATION_DURATION}
        className="main"
      >
        <Main productColor={product.color}>
          <Infos>
            <Protein>
              <span>{product.protein}</span> {translations.proteinGramageLabel}
            </Protein>
            <Title>
              <span>{product.flavour}</span>
              {product.category}
            </Title>
            <Intro>{product.intro}</Intro>
          </Infos>
          {swavenData.ean && (
            <AccordionTitle
              as="button"
              isSwaven
              className="swn-tag-wtb-btn"
              data-eans={swavenData.ean}
            >
              {swavenData.label}
            </AccordionTitle>
          )}
          {retailers && (
            <StyledAccordion
              hiddenLabel={
                <AccordionTitle>{translations.pdpEcomCtaLabel}</AccordionTitle>
              }
              visibleLabel={
                <AccordionTitle>{translations.pdpEcomCtaLabel}</AccordionTitle>
              }
            >
              <StyledRetailersList retailers={retailers} />
            </StyledAccordion>
          )}
          {healthStarRating && healthStarRatingImg && (
            <HSRContainer>
              <img
                src={healthStarRatingImg}
                alt={`Health Star Rating: ${healthStarRating}`}
              />
              {product.hsrLinkText && (
                <HSRLinkText
                  enableRenderer
                  doc={product.hsrLinkText}
                  alignment="center"
                />
              )}
            </HSRContainer>
          )}
          <SectionTitle title={translations.whatsInsideLabel} />
          <WhatsInsideDescription>
            {whatsInside.description}
          </WhatsInsideDescription>
          {whatsInside.icons && (
            <WhatsInsideList>
              {whatsInside.icons.map((icon) => (
                <li key={`whatsinside_${icon.label}`}>
                  <WhatsInside {...icon} />
                </li>
              ))}
            </WhatsInsideList>
          )}
          {product.whatsInsideLinkText && (
            <WhatsInsideLinkText
              doc={product.whatsInsideLinkText}
              enableRenderer
              alignment="center"
            />
          )}
          {imagePush && <ImagePush {...imagePush} />}
          <SectionTitle title={translations.nutritionalFactsLabel} />
          <MajorFacts>
            {majorFacts.map(({ label, value }) => (
              <MajorFactsItem key={`majorfact_${label}`}>
                <MajorFact>
                  {label} <span>{value}</span>
                </MajorFact>
              </MajorFactsItem>
            ))}
          </MajorFacts>
          <NutritionalTable {...nutritionalTable} />
          <SectionTitle title={translations.ingredientsLabel} />
          <Ingredients doc={ingredients} enableRenderer />
          {relatedCardsCarousel && (
            <RelatedCardsCarousel {...relatedCardsCarousel} />
          )}
          {categoryPush && <ImagePush {...categoryPush} isCategoryPush />}
          {legalNotice && legalVisible && (
            <LegalNoticeWrapper>
              <LegalNotice>
                <p>{legalNotice}</p>
                <CloseButton
                  onClick={() => setLegalVisible(false)}
                  type="button"
                >
                  <Close />
                </CloseButton>
              </LegalNotice>
            </LegalNoticeWrapper>
          )}
        </Main>
      </CSSTransition>
    </>
  );
};

Product.propTypes = {
  nutritionalTable: PropTypes.shape(NutritionalTable.propTypes),
  retailers: RetailersList.propTypes.retailers,
  imagePush: PropTypes.shape(ImagePush.propTypes),
  relatedCardsCarousel: PropTypes.shape(RelatedCardsCarousel.propTypes),
  categoryPush: PropTypes.shape(ImagePush.propTypes),
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    flavour: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    protein: PropTypes.string.isRequired,
    image: PropTypes.shape(Img.propTypes).isRequired,
    background: PropTypes.shape({
      small: PropTypes.shape(Img.propTypes),
      large: PropTypes.shape(Img.propTypes).isRequired,
    }),
    intro: PropTypes.string.isRequired,
    whatsInsideLinkText: PropTypes.object,
    hsrLinkText: PropTypes.object,
  }),
  majorFacts: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  ingredients: PropTypes.object.isRequired,
  flavours: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  whatsInside: PropTypes.shape({
    description: PropTypes.string.isRequired,
    icons: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        label: PropTypes.string,
      })
    ).isRequired,
  }),

  healthStarRating: PropTypes.number,
  hsrLinkText: PropTypes.object,
  legalNotice: PropTypes.string,
  swavenData: PropTypes.shape({
    label: PropTypes.string,
    ean: PropTypes.string,
  }),
};

export default Product;
