import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';
import { LINK_TYPES } from '@atoms/Link';
import { adapter as nutritionalTableAdapter } from '@molecules/NutritionalTable';
import { adapter as retailerButtonAdapter } from '@atoms/RetailerButton';
import { adapter as imagePushAdapter } from '@molecules/ImagePush';
import {
  adapter as relatedCardsAdapter,
  adapter as relatedCardsCarouselAdapter,
} from '@organisms/RelatedCards';

export default ({
  nutritionalTable,
  swavenEanNumber,
  swavenButtonLabel,
  retailers,
  imagePush,
  relatedCards,
  relatedCardsCarousel,
  categoryPush,
  productColor,
  id,
  flavour,
  proteinGramage,
  productCategory,
  productPicture,
  lifestylePictureMobile,
  lifestylePictureDesktop,
  introText,
  majorNutritionalFact1Label,
  majorNutritionalFact1Value,
  majorNutritionalFact2Label,
  majorNutritionalFact2Value,
  majorNutritionalFact3Label,
  majorNutritionalFact3Value,
  majorNutritionalFact4Label,
  majorNutritionalFact4Value,
  ingredientDescription,
  whatsInsideDescription,
  whatsInsideIcons,
  legalNotice,
  healthStarRating,
  hsrLinkText,
  whatsInsideLinkText,
}) => {
  const majorFacts = [
    {
      label: majorNutritionalFact1Label,
      value: majorNutritionalFact1Value,
    },
    majorNutritionalFact2Label &&
      majorNutritionalFact2Value && {
        label: majorNutritionalFact2Label,
        value: majorNutritionalFact2Value,
      },
    majorNutritionalFact3Label &&
      majorNutritionalFact3Value && {
        label: majorNutritionalFact3Label,
        value: majorNutritionalFact3Value,
      },
    majorNutritionalFact4Label &&
      majorNutritionalFact4Value && {
        label: majorNutritionalFact4Label,
        value: majorNutritionalFact4Value,
      },
  ];

  const useSwaven = swavenButtonLabel && swavenEanNumber;

  return {
    product: {
      id,
      color: productColor,
      flavour,
      category: productCategory.name,
      protein: proteinGramage,
      image: getImageFields(productPicture, IMG_TYPES.fluid),
      background: {
        small: getImageFields(lifestylePictureMobile, IMG_TYPES.fluid),
        large: getImageFields(lifestylePictureDesktop, IMG_TYPES.fluid),
      },
      intro: introText.introText,
      hsrLinkText,
      whatsInsideLinkText,
    },
    nutritionalTable: nutritionalTableAdapter(nutritionalTable),
    swavenData:
      (useSwaven && { label: swavenButtonLabel, ean: swavenEanNumber }) || {},
    retailers:
      retailers && retailers.map((retailer) => retailerButtonAdapter(retailer)),
    imagePush: imagePush && imagePushAdapter(imagePush),
    relatedCards: relatedCards && relatedCardsAdapter(relatedCards),
    relatedCardsCarousel:
      relatedCardsCarousel && relatedCardsCarouselAdapter(relatedCardsCarousel),
    categoryPush: categoryPush && {
      pictures: {
        desktop: getImageFields(categoryPush.imageDesktop, IMG_TYPES.fluid),
        mobile: getImageFields(categoryPush.imageMobile, IMG_TYPES.fluid),
      },
      cta: {
        title: categoryPush.ctaText,
        label: categoryPush.ctaText,
        url: `${categoryPush.productCategory.slug}/${categoryPush.productCategory.product_flavour[0].slug}`,
        type: LINK_TYPES.internal,
        isProduct: true,
      },
    },
    majorFacts: majorFacts.filter((fact) => fact !== null),
    ingredients: ingredientDescription,
    flavours: productCategory.flavours.map(
      ({
        slug,
        id: flavourId,
        productColor: flavourProductColor,
        flavour: flavourFlavour,
      }) => ({
        slug: `${productCategory.slug}/${slug}`,
        id: flavourId,
        color: flavourProductColor,
        flavour: flavourFlavour,
      })
    ),
    whatsInside: {
      description: whatsInsideDescription.whatsInsideDescription,
      icons: whatsInsideIcons,
    },
    healthStarRating,
    legalNotice: legalNotice && legalNotice.legalNotice,
  };
};
